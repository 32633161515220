import httpClient from 'axios';
import { getId } from './id-worker';
import {
    getApi,
    postApi,
    putApi,
    delApi
} from '../request/request.js';
import { baseURL } from "@/config.js"
/**
 * 最新版的没有了Wrapper构造函数，直接配置
 * 原来为new OSS.Wrapper
 * 以下源码思路来源于阿里云OSS官方文档
 * 详情https://help.aliyun.com/document_detail/64041.html?spm=5176.208357.1107607.23.6ff6390fR0DEtx
 * @type {{readonly default?: Client}|Client}
 */
const OSS = require('ali-oss');

/**
 * 上传断点 建议使用vuex
 */
let tempCheckpoint;

const getClientConfig = () => {
    // 这里未封装请求  自行替换
    // return httpClient.post('/platform-oss/file/oss/sts/config');
    return postApi('/platform-oss/file/oss/sts/config')
};

const remadeFileName = (fileName) => {
    return `${getId()}${fileName.substring(fileName.lastIndexOf('.'), fileName.length)}`;
};

/**
 * 获取文件
 * @param objectKey
 * @returns {Promise<*>}
 */
export async function getObject(objectKey) {
    try {
        return await getClientConfig().then((response) => {
            const { accessKeyId, accessKeySecret, bucket, region, stsToken, dir } = response.data.data;
            const client = new OSS({
                accessKeyId, accessKeySecret, bucket, region, stsToken
            });
            client.get(`${dir}${objectKey}`);
        })
    } catch (e) {
        console.log(e);
    }
}

/**
 * 单次上传
 * @param objectKey
 * @param fileObject
 * @param options
 * @returns {Promise<*>}
 */
export function putObject(fileObject, options) {
    return new Promise((res, rej) => {
        try {
            // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
            return getClientConfig().then((response) => {
                const { accessKeyId, accessKeySecret, bucket, region, stsToken, callback, dir } = response.data;
                const client = new OSS({
                    accessKeyId, accessKeySecret, bucket, region, stsToken
                });
                const { name, raw } = fileObject;
                const objectKey = `${dir}${remadeFileName(name)}`;
                client.put(objectKey, raw, {
                    ...options, ...{
                        callback: { ...JSON.parse(atob(callback)), ...options.callback }
                    }
                }).then(e => {
                    res(e)
                });
            });
        } catch (e) {
            rej(e)
        }
    })
}

/**
 * 分片上传 文件大于100M应考虑使用该上传方式
 * @param fileObject
 * @param options
 * @returns {Promise<*>}
 */
export async function multipartUpload(fileObject, options) {
    try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        return await getClientConfig().then((response) => {
            const { accessKeyId, accessKeySecret, bucket, region, stsToken, callback, dir } = response.data.data;
            const client = new OSS({
                accessKeyId, accessKeySecret, bucket, region, stsToken
            });
            const { name, raw } = fileObject;
            const objectKey = `${dir}${remadeFileName(name)}`;
            client.multipartUpload(objectKey, raw, {
                ...options, ...{
                    progress: function (p, checkpoint) {
                        // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
                        tempCheckpoint = checkpoint;
                    },
                    meta: {},
                    mime: '',
                    callback: { ...JSON.parse(atob(callback)), ...options.callback }
                },
            })
        })
    } catch
    (e) {
        console.log(e);
    }
}

/**
 * 恢复上传
 * @param objectKey
 * @param fileObject
 * @param options
 * @returns {Promise<*>}
 */
async function resumeUpload(objectKey, fileObject, options) {
    try {
        return await getClientConfig().then((response) => {
            const { accessKeyId, accessKeySecret, bucket, region, stsToken, callback, dir } = response.data.data;
            const client = new OSS({
                accessKeyId, accessKeySecret, bucket, region, stsToken
            });
            const { name, raw } = fileObject;
            const objectKey = `${dir}${remadeFileName(name)}`;
            client.multipartUpload(objectKey, raw, {
                ...options, ...{
                    progress: function (p, checkpoint) {
                        tempCheckpoint = checkpoint;
                    },
                    checkpoint: tempCheckpoint,
                    meta: {},
                    mime: '',
                    callback: { ...JSON.parse(atob(callback)), ...options.callback }
                }
            })
        })
    } catch (e) {
        console.log(e);
    }
}


/* editorUpload
    wangEditor编辑器上传图片回调
*/
export function wangEditorUpload(resultFiles, insertImgFn) {
    // resultFiles 是 input 中选中的文件列表
    // insertImgFn 是获取图片 url 后，插入到编辑器的方法
    // 按照格式组装参数 file 对象
    var file = {
        raw: resultFiles[0],
        name: resultFiles[0].name,
    };
    putObject(file, {
        meta: {},
        callback: {
            customValue: {},
        },
    })
        .then((res) => {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url);
        })
        .catch((err) => {
            console.log(err);
        });
};

import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import {
	getApi
} from '@/request/request'
import {
	Message
} from "element-ui"
Vue.use(Router)


const router = new Router({
	mode: 'hash',
	routes: [{
		path: '/login',
		component: () => import('@/views/common/login.vue'),
		name: 'login',
		meta: {
			title: '登录'
		}
	},

	]
})
/* 需要异步挂载路由 */
let asyncRouter = [{
	path: "/",
	component: () => import('@/views/main.vue'),
	name: '/',
	redirect: '/home',
	children: [{
		path: '/home',
		component: () => import('@/views/common/home.vue'),
	},
	{
		path: '/setup-template-event',
		component: () => import('@/views/setup/template/event/index.vue'),
		meta: {
			title: '事件库模板'
		}
	},
	{
		path: '/setup-event-import',
		component: () => import('@/views/setup/event/import.vue'),
		meta: {
			title: '事件库导入'
		}
	},
	{
		path: '/setup-menu',
		component: () => import('@/views/setup/menu/index.vue'),
		meta: {
			title: '应用菜单管理',

		}
	},
	{
		path: '/setup-residentMenu',
		component: () => import('@/views/setup/residentMenu/index.vue'),
		meta: {
			title: '居民应用菜单管理',
	
		}
	},
	{
		path: '/user-detail',
		component: () => import('@/views/user/list/detail.vue'),
		meta: {
			title: '账户详情',

		}
	},
	{
		path: '/user-add',
		component: () => import('@/views/user/list/form.vue'),
		meta: {
			title: '账户详情',
		}
	},
	{
		path: '/resident-detail',
		component: () => import('@/views/user/residentList/detail.vue'),
		meta: {
			title: '账户详情',
	
		}
	},
	{
		path: '/resident-add',
		component: () => import('@/views/user/residentList/form.vue'),
		meta: {
			title: '账户详情',
		}
	},
	{
		path: '/setup-taskMonitor-list',
		component: () => import('@/views/setup/taskMonitor/list.vue'),
		meta: {
			title: '任务计算监控详情',
		}
	},
	{
		path: '/user-thirdparty-form',
		component: () => import('@/views/user/thirdparty/form.vue'),
		meta: {
			title: '第三方配置',
		}
	},
	]
},
{
	path: '/404',
	component: () => import('@/views/common/404.vue'),
}
]
/* 免登白名单 */
const whiteList = ['/login', '/404'];
var isAddRouter = false;
router.beforeEach((to, from, next) => {
	/* 1.判断是否有token */
	let token = Vue.cookie.get('token');
	if (token && to.path !== '/login') {
		/* 2.判断是否拉取过路由 */

		if (!store.state.common.isAddRouter) {
			getApi('/platform-admin/menu/nav').then((res) => {
				if (res.data && res.data.length) {
					store.commit("common/updateIsAddRouter", true);
					let list = [];
					/* 递归处理树 */
					handleResult(list, res.data);
					/* 生成可访问的路由列表 */
					var routerList = list.map(item => {
						return {
							path: item.url,
							name: item.url,
							component: () => import(`@/views${item.location}.vue`),
							meta: {
								title: item.name
							}
						}
					})
					asyncRouter[0].children = asyncRouter[0].children.concat(routerList)
					router.addRoutes(asyncRouter);
					sessionStorage.setItem('menuList', JSON.stringify(res.data || '[]'))
					// sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
					next({
						...to,
						replace: true
					}) // hack方法 确保addRoutes已完成
				} else {
					Message.error('当前用户暂无权限！');
					next('/login'); // 没权限重定向到登录页
					store.commit("menu/updateIsAddRouter", false)
				}

			})
		} else {
			next();
		}

	} else {
		if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
			next();
		} else {
			next('/login'); // 否则全部重定向到登录页
		}
	}

})
/* 递归处理树 */
function handleResult(arr, list) {
	list.map(item => {
		let row = JSON.parse(JSON.stringify(item));
		row.children = null;
		if (item.type === 1) arr.push(item);
		if (item.children && item.children.length) {
			handleResult(arr, item.children)
		}
	})
}

export default router

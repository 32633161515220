<template>
	<div class="upBox">
		<el-upload list-type="picture-card" :class="fileList.length?'hide':''" :limit="1" :on-remove="reomve"
			accept="image/*" :file-list="fileList" action="" :auto-upload="false" :on-change="change">
			<i class="el-icon-plus"></i>
		</el-upload>
		<canvas id="canvas" width="" height=""></canvas>
	</div>
</template>
<script>
	import {
		putObject
	} from "@/utils/oss";
	export default {
		props: {
			value: {
				//图片回显
				type: String,
				default: "",
			},
			contraction:{
				//是否压缩
				type: Boolean,
				default: true,
			}
		},
		data() {
			return {
				url: "", //图片显示
				fileList: [
					//用fileList 控制图片回显
				],
			};
		},
		watch: {
			value() {
				this.setFile();
			},
		},
		computed: {},
		created() {
			this.setFile();
		},
		methods: {
			//   单个图片回显
			setFile() {
				if (this.value) {
					this.fileList = [{
						url: this.value
					}];
				} else {
					this.fileList = []
				}
				console.log(this.fileList);
			},
			//  删除图片操作
			reomve(file, fileList) {
				this.fileList = fileList;
				this.$emit("result", "");
			},
			// 图片压缩
			contractionImg(file) {
				let {
					type,
					name
				} = file.raw
				return new Promise((resolve, reject) => {
					let ratio = 2;
					var img = new Image();
					let url = window.URL.createObjectURL(file.raw);
					img.src = url;
					setTimeout(() => {
						let w = img.width / ratio;
						let h = img.height / ratio;
						let canvas = document.getElementById('canvas');
						let content = canvas.getContext('2d');
						canvas.width = w;
						canvas.height = h;
						content.drawImage(img, 0, 0, w, h);
						canvas.toBlob(function(blob) {
							// blob 格式的图片 转成file对象，这里主要看接口支不支持blob格式的文件上传，如果支持就没有必要转
							let file = new File([blob], name, {
								type: type
							})
							resolve({
								type: type,
								name: name,
								raw: file
							})
						}, `image/${type.split('/')[1]}`, 0.75) // 0.7 是文件压缩程度
					}, 100)
				})
			},
			async change(file, fileList) {
				let image = file;
				this.fileList = [];
				if(this.contraction){
					await this.contractionImg(file).then(res => {
						image = res;
					});
				}
				putObject(image, {
					meta: {},
					callback: {
						customValue: {},
					},
				}).then((res) => {
					console.log(res);
					if (res.url) {
						this.fileList = [{
							url: res.url,
						}, ];
						this.$emit("result", res.url);
					} else {
						this.$message.error("上传图片失败！");
					}
				});
			},
		},
	};
</script>

<style lang='scss' scoped>
	.hide /deep/ .el-upload--picture-card {
		display: none;
	}

	.upBox {
		position: relative;
	}

	#canvas {
		position: absolute;
		right: 999999px;
	}
</style>

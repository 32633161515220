import { Message } from "element-ui";

export default class Paste {
    list = []
    constructor(id, key, callback) {
        /* 
            id：元素id
            key:包含label和prop的数组，用于生成json数据
            callback:回调（处理后的数组）
        */
        this.id = id;
        this.key = key;
        this.callback = callback;
        this.paste()
    }
    paste() {
        var that = this;
        document.getElementById(this.id).onpaste = function (e) {
            if (that.checkBrowser() !== 'Chrome' && that.checkBrowser() !== "Firefox") {
                Message.error("粘贴功能仅支持谷歌Chrome和火狐Firefox,请尝试excel文件导入");
                return;
            }
            if (e.target.tagName !== 'INPUT') {
                if (e.clipboardData) {
                    /* 判断数据格式 */
                    if (e.clipboardData.items.length > 2) {
                        that.handleArray_excel(e.clipboardData);
                    } else {
                        that.handleArray_table(e.clipboardData);
                    }
                } else {
                    Message.error("浏览器版本过低，暂时不支持该功能");
                }
            }
        }
    }
    handleArray_excel(ev) {
        var str = ev.getData('text');
        var arr = str.split(/[\s\n]/);
        // 去除末尾空项
        for (var x = arr.length - 1; x > 0; x--) {
            if (arr[x] === "" || arr[x] === undefined || arr[x] === null) {
                arr.pop()
            } else {
                break
            }
        }
        var newArr = [];
        if (this.checkBrowser() === 'Chrome') {
            for (var x = 0; x < arr.length; x = x + this.key.length + 1) {
                var obj = {};
                this.key.map((label, index) => {
                    obj[label] = arr[x + index] || ""
                })
                newArr.push(obj);
            }
        }
        if (this.checkBrowser() === "Firefox") {
            for (var x = 0; x < arr.length; x = x + this.key.length) {
                var obj = {};
                this.key.map((label, index) => {
                    obj[label] = arr[x + index] || ""
                })
                newArr.push(obj);
            }
        }
        this.list = newArr;
        this.callback(newArr);
    }

    handleArray_table(ev) {
        var str = ev.getData('text');
        console.log(str)
        var arr = str.split("\n");
        var newArr = [];
        for (var x = 0; x < arr.length; x = x + this.key.length) {
            var obj = {};
            this.key.map((label, index) => {
                obj[label] = arr[x + index] || ""
            })
            newArr.push(obj);
        }
        this.list = newArr;
        this.callback(newArr);
    }
    /* 判断浏览器 */
    checkBrowser() {
        var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
        var isIE = userAgent.indexOf("compatible") > -1
            && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
        var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
        var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
        var isSafari = userAgent.indexOf("Safari") > -1
            && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
        var isChrome = userAgent.indexOf("Chrome") > -1
            && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

        if (isIE) {
            var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
            reIE.test(userAgent);
            var fIEVersion = parseFloat(RegExp["$1"]);
            if (fIEVersion == 7) {
                return "IE7";
            } else if (fIEVersion == 8) {
                return "IE8";
            } else if (fIEVersion == 9) {
                return "IE9";
            } else if (fIEVersion == 10) {
                return "IE10";
            } else if (fIEVersion == 11) {
                return "IE11";
            } else {
                return "IE";
            }
        }
        if (isOpera) {
            return "Opera";
        }
        if (isEdge) {
            return "Edge";
        }
        if (isFF) {
            return "Firefox";
        }
        if (isSafari) {
            return "Safari";
        }
        if (isChrome) {
            return "Chrome";
        }

    }

}

import Vue from 'vue'

import jflayout from "./jf-layout" //基本布局
import jfpage from "./jf-page"  //分页
import jfimport from "./jf-import"  //分页
import jfossUploadImg from "./jf-ossUpload/img/index.vue"
import jfossUploadFile from "./jf-ossUpload/file/index.vue"
Vue.component("jf-layout", jflayout)
Vue.component("jf-page", jfpage)
Vue.component("jf-import", jfimport)
Vue.component("jf-ossUpload-img", jfossUploadImg)
Vue.component("jf-ossUpload-file", jfossUploadFile)
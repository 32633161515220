import Vue from 'vue';

Vue.directive('isAuth', {
	inserted: function(el, binding, vnode) {
		let bool = JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(binding.value) !== -
			1 ||
			false
		if (!bool) {
			debugger
			// el.parentNode.removeChild(el);
			console.log(el.parentNode);
			debugger
		}
	}
})

// 配置项
const type = 'prod';

const config = {
    dev: {
        baseURL: 'http://192.168.0.115:9999',
        tenantUrl: 'http://192.168.0.115:8887/#/redirect',
        residentUrl: 'http://192.168.0.115:8888/#/redirect'
    },
    prod: {
        baseURL: 'https://admin.dzjfz.com/interface',
        tenantUrl: 'https://yun.dzjfz.com/#/redirect',
        residentUrl: 'https://jm.dzjfz.com/#/redirect'
    }
}

export const baseURL = config[type].baseURL;
export const tenantUrl = config[type].tenantUrl;
export const residentUrl = config[type].residentUrl;


import axios from './axios.js'
import qs from 'qs'


/**
 * 查询接口调用（select）
 * @param {string} 请求接口名
 * @param {string} 请求数据
 * @param {boolean} 是否使用根目录 默认false
 * @param {boolean} 是否需要全屏出现loading,默认false
 * */
export function getApi(url, params, needToken, isRoot, needLoding) {
  if (params == "" || params == null || params == {} || params == undefined) {
    params = {}
  }
  if (needToken != false) {
    needToken = true;
  }
  if (isRoot) {
    isRoot = true
  } else {
    isRoot = false
  }
  if (needLoding == '' || needLoding == null || needLoding == undefined) {
    needLoding = false;
  }
  return axios({
    url,
    method: 'get',
    params,
    needToken,
    isRoot,
    needLoding
  });
}


/**
 * 新增接口调用（insert）
 * @param {string} 请求接口名
 * @param {string} 请求数据
 * @param {boolean} 是否使用根目录 默认false
 * @param {String} 请求头 json|form  默认为json
 * @param {boolean} 是否需要全屏出现loading,默认false
 * */
export async function postApi(url, data, needToken, header, isRoot, needLoding) {
  if (data == "" || data == null || data == {} || data == undefined) {
    data = {}
  }
  if (needToken != false) {
    needToken = true;
  }
  if (header == "" || header == null || header == undefined) {
    header = "application/json;charset=UTF-8"
  } else {
    if (header == 'json') {
	  // header={
	  // 	'content-type': 'application/json;charset=UTF-8',
	  // };
	  header='application/json;charset=UTF-8',
      data = JSON.stringify(data);
    } else if (header == 'form') {
	  // header={
	  // 	'content-type': "application/x-www-form-urlencoded",
	  // };
	  header='application/x-www-form-urlencoded',
      data = qs.stringify(data);
    }else if(header=='login'){
			header={
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': 'Basic cmVucmVuaW86cmVucmVuaW8='
			};
			 data = qs.stringify(data);
		}
  }
  if (isRoot) {
    isRoot = true
  } else {
    isRoot = false
  }

  if (needLoding == '' || needLoding == null || needLoding == undefined) {
    needLoding = false;
  }
  return axios({
    url,
    method: 'post',
    data: data,
    header,
    needToken,
    isRoot,
    needLoding
  });
}

/**
 * 修改接口调用（update）
 * @param {string} 请求接口名
 * @param {string} 请求数据
 * @param {boolean} 是否使用根目录 默认false
 * @param {String} 请求头 json|form  默认为json
 * @param {boolean} 是否需要全屏出现loading,默认false
 * */
export function putApi(url, data, needToken, header, isRoot, needLoding) {
  if (data == "" || data == null || data == {} || data == undefined) {
    data = {}
  }
  if (needToken != false) {
    needToken = true;
  }
  if (header == "" || header == null || header == undefined) {
    header = "application/json;charset=UTF-8"
  } else {
    if (header == 'json') {
      header = "application/json;charset=UTF-8"
      data = JSON.stringify(data);
    } else if (header == 'form') {
      header = "application/x-www-form-urlencoded"
      data = qs.stringify(data);
    }
  }
  if (isRoot) {
    isRoot = true
  } else {
    isRoot = false
  }
  if (needLoding == '' || needLoding == null || needLoding == undefined) {
    needLoding = false;
  }
  return axios({
    url,
    method: 'put',
    data: data,
    header,
    needToken,
    isRoot,
    needLoding
  });
}

/**
 * 删除接口调用（delete）
 * @param {string} 请求接口名
 * @param {string} 请求数据
 * @param {boolean} 是否使用根目录 默认false
 * @param {String} 请求头 json|form  默认为json
 * @param {boolean} 是否需要全屏出现loading,默认false
 * */
export function delApi(url, data, needToken, header, isRoot, needLoding) {
  if (data == "" || data == null || data == {} || data == undefined) {
    data = {}
  }
  if (needToken != false) {
    needToken = true;
  }
  if (header == "" || header == null || header == undefined) {
    header = "application/json;charset=UTF-8"
  } else {
    if (header == 'json') {
      header = "application/json;charset=UTF-8"
      data = JSON.stringify(data);
    } else if (header == 'form') {
      header = "application/x-www-form-urlencoded"
      data = qs.stringify(data);
    }
  }
  if (isRoot) {
    isRoot = true
  } else {
    isRoot = false
  }
  if (needLoding == '' || needLoding == null || needLoding == undefined) {
    needLoding = false;
  }
  return axios({
    url,
    method: 'delete',
    data: data,
    header,
    needToken,
    isRoot,
    needLoding
  });
}

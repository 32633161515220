<template>

  <el-upload :limit="1" :on-remove="reomve" :file-list="fileList" action="" :auto-upload="false" :on-change="change">
    <el-button size="small" type="primary">点击上传</el-button>
  </el-upload>

</template>
<script>
import { putObject } from "@/utils/oss";
export default {
  props: {
    value: {
      //图片回显
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: "", //图片显示
      fileList: [],
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    reomve(file, fileList) {
      this.fileList = fileList;
      this.$emit("result", "");
    },
    change(file, fileList) {
      this.fileList = [];
      console.log(file);
      putObject(file, {
        meta: {},
        callback: {
          customValue: {},
        },
      }).then((res) => {
        console.log(res);
        if (res.url) {
          this.fileList = [
            {
              ...file,
              url: res.url,
            },
          ];
          this.$emit("result", res.url);
        } else {
          this.$message.error("上传失败！");
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped >
</style>

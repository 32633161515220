import SnowflakeId from "snowflake-id";

/**
 * 初始化雪花Id对象
 * @type {Snowflake}
 */
const snowflake = new SnowflakeId();

/**
 * 生成id 类型为string
 * @returns {null|[]}
 */
export const getId = () => {
    return snowflake.generate();
};

import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import VueCookie from 'vue-cookie'

import ElementUI from 'element-ui';
import vueXlsxTable from 'vue-xlsx-table'
import httpRequest from '@/utils/httpRequest'
import {isAuth} from '@/utils'
// 请求
import {getApi, postApi, putApi, delApi} from './request/request.js';
import createId from "@/utils/createId.js";
import directive from "@/utils/directive.js"
import {baseURL, tenantUrl, residentUrl} from "@/config.js"

import "@/components/index.js"  //挂载组件
import "@/utils/dialogDrag.js"  //弹窗拖拽
import 'element-ui/lib/theme-chalk/index.css'; //element框架样式
import '@/assets/scss/index.scss' //公共样式
import '@/assets/css/project.css' //公共样式

Vue.use(ElementUI);
Vue.use(VueCookie);
Vue.use(vueXlsxTable, {rABS: false})
Vue.config.productionTip = false
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.isAuth = isAuth     // 权限方法
Vue.prototype.$get = getApi;
Vue.prototype.$post = postApi;
Vue.prototype.$put = putApi;
Vue.prototype.$del = delApi;
Vue.prototype.$createId = createId; //创建id
Vue.prototype.$tenantUrl = tenantUrl  //跳转至企业端的免密登录地址
Vue.prototype.$residentUrl = residentUrl  //跳转至居民端的免密登录地址
Vue.prototype.$baseURL = baseURL  //接口地址
// 如果是生产环境的话，重写console.log方法清除控制台日志
if (process.env.NODE_ENV === 'production') {
    window.console.log = function () {
    }
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")

<template>
  <div id="paste" class="paste">
    <div class="title">
      先
      <el-button class="download" size="small" type="primary">
        <a :href="href" :download="download">下载模板</a>
      </el-button>
      填写模板完毕, 再 ①导入模板内容
      <el-button class="download" size="small" type="primary">
        <vue-xlsx-table class="importFile" @on-select-file="handleSelectedFile">导入文件</vue-xlsx-table>
      </el-button>
      或者 ②将模板数据直接 复制粘贴
    </div>
    <div class="btnBox">
      <el-button size="small" type="primary" icon="el-icon-circle-plus" :disabled="isNext" @click="addRow()">新增行</el-button>
      <el-button size="small" type="danger" icon="el-icon-delete" :disabled="!isSelect || isNext" @click="remove">删除</el-button>
    </div>
    <el-table ref="pasteTable" id="pasteTable" :data="pasteData" @selection-change="selectionChange" empty-text="点击空白处，再复制（ctrl+v），单次限制导入500条" border style="width: 100%">
      <el-table-column type="selection" align="center" width="50">
      </el-table-column>
      <el-table-column v-for="item in label" :key="item.prop" :prop="item.prop" :label="item.label">
        <template slot-scope="{row}">
          <input type="text" :disabled="isNext" v-model="row[item.prop]">
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-button size="small" @click="$router.go(-1)" :disabled="loading">返 回</el-button>
      <el-button v-if="!isNext" size="small" type="primary" @click="nextClick" >下一步</el-button>
      <el-button v-if="isNext" size="small" @click="prevClick" :disabled="loading">上一步</el-button>
      <el-button v-if="isNext" :loading="loading" size="small" type="primary" @click="confirm">{{loading?'正在处理':'确认'}}</el-button>
    </div>
  </div>
</template>
<script>
import Paste from "@/utils/paste.js";

export default {
  props: {
    /* 提交至后端的接口地址 */
    api: {
      type: String,
      default: "",
    },
    /* 下一步点击回调（数组，dom） */
    next: {
      type: Function,
      default() {
        return () => {};
      },
    },
    //模板下载地址
    href: {
      type: String,
      default: "javascript:;",
    },
    //模板文件名称
    download: {
      type: String,
      default: "模板文件.xlsx",
    },
    //表格数组对象key值
    label: {
      type: Array,
      default() {
        return [];
      },
    },
    /* 对excel数据进行特殊处理，比如时间*/
    handleExcel: {
      type: Function | String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      pasteData: [],
      isSelect: false,
      selectList: [],
      isNext: false, // 是否可以下一步，将数据上传服务器
      loading: false,
    };
  },
  mounted() {
    this.initPaste();
  },
  methods: {
    clearAllError() {
      var table = window.document.getElementById("pasteTable");
      var cell = table
        .getElementsByTagName("tbody")[0]
        .getElementsByClassName("cell");
      console.log(cell.length);
      for (var i = 0; i < cell.length; i++) {
        cell[i].style.border = "none";
        cell[i].title = "";
      }
    },
    confirm() {
      this.loading = true;
      this.$post(this.api, this.pasteData)
        .then((res) => {
          /* 如果返回空数据代表数据正确 */
          if (res && res.code === 0 && res.data && !res.data.length) {
            this.$message.success("导入成功");
            this.$router.back();
          }
          /* 如果返回数组有数据代表数据验证报错 */
          if (res.data && res.data.length) {
            this.$message.warning("数据格式有误，请修正后再提交");
            /* 将后端标记的错误展示到页面中 */
            var table = window.document.getElementById("pasteTable");
            this.clearAllError();
            res.data.map((item) => {
              var row = table.getElementsByTagName("tr")[item.row];
              var cell = row.getElementsByClassName("cell")[item.col];
              cell.style.border = "1px solid pink";
              cell.title = item.reason;
            });
            this.isNext = false;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 上一步
    prevClick() {
      this.isNext = false;
    },
    // 下一步
    nextClick() {
      this.isNext = this.next(
        this.pasteData,
        window.document.getElementById("pasteTable")
      );
     if(this.isNext)  this.clearAllError();
      console.log(this.isNext);
    },
    // 选择文件导入
    handleSelectedFile(convertedData) {
      this.isNext = false; //重置到需要验证的状态
      console.log(convertedData);
      var arr = convertedData.body || [];
      console.log(arr);
      var list = arr.map((item) => {
        var obj = {};
        this.label.map((name) => {
          obj[name.prop] = item[name.label];
        });
        obj.id = this.$createId();
        return obj;
      });
      /* 对excel数据进行特殊处理，比如时间*/
      var array = list;
      if (this.handleExcel) {
        array = this.handleExcel(JSON.parse(JSON.stringify(list)));
      }
      this.pasteData = this.pasteData.concat(array);
    },
    // 选择数据
    selectionChange(arr) {
      console.log(arr);
      this.selectList = JSON.parse(JSON.stringify(arr));
      this.isSelect = Boolean(arr.length);
    },
    // 删除数据
    remove() {
      this.selectList.map((item) => {
        console.log(item.id);
        for (var i = 0; i < this.pasteData.length; i++) {
          if (item.id == this.pasteData[i].id) {
            console.log(item.id);
            this.pasteData.splice(i, 1);
            break;
          }
        }
      });
      this.clearAllError();
    },
    // 添加行
    addRow(row) {
      
      var obj = row || {};
      this.label.map((item) => {
        obj[item.prop] = null;
      });
      obj.id = this.$createId();
      this.pasteData.push(obj);
    },
    // 初始化实例，注册复制功能
    initPaste() {
      var key = this.label.map((item) => {
        return item.prop;
      });
      new Paste("paste", key, (arr) => {
        console.log(arr);
        arr.map((item) => {
          item.id = this.$createId();
        });

        if (this.handleExcel) {
          arr = this.handleExcel(JSON.parse(JSON.stringify(arr)));
        }
        if (arr.length) {
          this.pasteData = this.pasteData.concat(arr);
          this.$forceUpdate;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped >
/deep/ .el-table th,
/deep/.el-table td {
  text-align: center;
}
.paste {
  min-height: calc(100vh - 163px);
  padding-bottom: 60px;
  position: relative;
  .btnBox {
    margin-bottom: 15px;
  }
  .download {
    padding: 0 0 0 0;
    a {
      padding: 9px 15px;
      display: inline-block;
      color: #fff;
    }
    .importFile {
      background: #409eff;
      /deep/ button {
        background: #409eff;
        width: 78px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .title {
    margin-bottom: 15px;
    display: inline-block;
  }
  input {
    width: 100%;
    min-height: 40px;
    border-color: transparent;
    text-align: center;
    padding: 0 12px;
  }
  input.upload {
    display: none;
  }
  /deep/ td {
    padding: 0;
    > div.cell {
      min-height: 40px;
      line-height: 40px;
      padding: 0;
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    border-top: 1px solid #ccc;
    bottom: 0;

    padding-top: 15px;

    text-align: center;
    button {
      margin: 0 20px;
    }
  }
}
</style>

import axios from 'axios';
import router from '@/router';
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import {
	clearLoginInfo
} from '@/utils'
import {
	Message,
	Loading
} from 'element-ui'
import { baseURL } from "@/config.js"
let loading = null //这里是loading
let needLoading = false;

const Service = axios.create({
	timeout: 120000, // 请求超时时间
})

/****** request拦截器==>对请求参数做处理 ******/
Service.interceptors.request.use(config => {
	const _this = this;
	let url = null;
	config.withCredentials = true //请求自动添加Cookie
	// if (config.isRoot) {
	// 	url = process.env.ROOT_URL+ process.env.DIRECTORY
	// } else {
	// 	url = process.env.ROOT_URL 
	// }

	config.dataType = 'jsonp';
	config.baseURL = baseURL;

	if (config.needLoding) {
		needLoading = config.needLoding
		loading = Loading.service({
			lock: true,
			text: 'loading...'
		})
	}
	config.headers['VERSION'] = "ymj";
	if (config.header && config.header.Authorization) {
		config.headers['Content-Type'] = config.header['content-type'];
		config.headers['Authorization'] = config.header.Authorization;

	} else if (config.header && !config.header.Authorization) {
		config.headers['Content-Type'] = config.header;
	}
	if (config.needToken !== false) {
		if (Vue.cookie.get('token')) {
			config.headers.Authorization = 'Bearer ' + Vue.cookie.get('token');

		} else {
			// console.log("token过期了")
		}
	}
	return config;
}, error => { //请求错误处理
	Message({
		type: 'error',
		message: error
	});
	Promise.reject(error)
});

/****** respone拦截器==>对响应做处理 ******/
Service.interceptors.response.use(
	response => {
		if (needLoading) {
			loading.close();
		}
		//这里根据后端提供的数据进行对应的处理
		if (!response.data.code) {
			return response.data;
		} else {
			// if (response.data.code != 500) {
			// 	Message({
			// 		type: 'warning',
			// 		message: response.data.msg,
			// 	})
			// } else {
			// 	if (response.data.msg == null) {
			// 		Message({
			// 			type: 'warning',
			// 			message: '您的信息过期了，请重新登录！将自动跳转登录页...',
			// 			onClose: function() {
			// 				clearLoginInfo();
			// 				router.push('/');
			// 			},
			// 		})
			// 	}
			// }
			if (response.data.code == 401) {
				clearLoginInfo();
				VueCookie.set('token','')
				Message({
					type: 'warning',
					message: '登录信息已过期，请重新登录！',
				})
				// router.push('/login');
				window.location.reload()
			} else if (response.data.code != 0) {
				Message({
					type: 'warning',
					message: response.data.msg,
				})
			}
			return response.data;
		}
	},
	error => {
		//响应错误处理
		if (needLoading) {
			loading.close();
		}
		let errorInfo = JSON.parse(JSON.stringify(error));
		let errData = errorInfo.message && errorInfo.message.split(" ");
		if (errData[errData.length - 1] == '403') {
			Message({
				type: 'warning',
				message: '服务器异常，请重新登录。将自动跳转登录...',
				onClose: function () {
					clearLoginInfo();
					router.push('/');
				},
			})
			return false;
		} else {
			Message({
				type: 'error',
				message: errorInfo.message
			});
		}
		return Promise.reject(error)
	}
);

export default Service

<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {};
</script>
<style>
* {
  margin: 0 0 0 0;
  list-style: none;
  padding: 0 0 0 0;
  box-sizing: border-box;
}
</style>
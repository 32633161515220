<template>
	<div>
		<el-pagination v-show="total>0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page.sync="currentPage" :page-sizes="pageSizes" :page-size.sync="pageSize" :layout="layout"
			:background="background" :total="total">
		</el-pagination>

	</div>
	<!-- 调用示例
        <Pagination        
            :total="total"
            :page.sync="form.pageNum"
            :limit.sync="form.pageSize"
            @change="getList()"
        />
    -->
</template>
<script>
	export default {
		props: {
			layout: {
				type: String,
				default: "total, sizes, prev, pager, next, jumper",
			},
			pageSizes: {
				type: Array,
				default () {
					return [10, 20, 30, 50, 100, 300];
				},
			},
			total: {
				required: true,
				type: Number,
			},
			page: {
				type: Number,
				default: 1,
			},
			limit: {
				type: Number,
				default: 10,
			},

			background: {
				type: Boolean,
				default: true,
			},
		},
		computed: {
			currentPage: {
				get() {
					return this.page;
				},
				set(val) {
					this.$emit("update:page", val);
				},
			},
			pageSize: {
				get() {
					return this.limit;
				},
				set(val) {
					this.$emit("update:limit", val);
				},
			},
		},
		methods: {
			handleSizeChange(val) {
				this.$emit("change", {
					pageNo: this.currentPage,
					pageSize: val
				});
			},
			handleCurrentChange(val) {
				this.$emit("change", {
					pageNo: val,
					pageSize: this.pageSize
				});
			},
		},
	};
</script>

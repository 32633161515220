<template>
	<div class="layout">
		<div class="head-btn">
			<slot name="head"></slot>
		</div>
		<div class="body-table">
			<slot name="body"></slot>
		</div>
		<div class="foot-page">
			<slot name="foot"></slot>
		</div>
		<slot />
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.head-btn {
		line-height: 40px;
		// padding-bottom: 20px;
		// border-bottom: 1px solid #eee;
	}

	.head-btn /deep/ .el-form-item {
		margin-bottom: 8px;
	}

	.body-table /deep/ .el-table {
		background: #F9FAFC;
		margin-top: 15px;
		.el-button--mini {
			border: none;
			background: none;
			padding: 0 5px;
			&:hover {
				opacity: 0.7;
				text-decoration: underline;
			}

			&.el-button--danger {
				color: #f56c6c;
			}

			&.el-button--default {
				color: #409eff;
			}
		}
	}

	.foot-page {
		text-align: center;

		>div {
			display: inline-block;
		}
	}
</style>
